import React, { useContext } from "react";
import { graphql, Link } from "gatsby";
import Img from "gatsby-image"
import { Typography, Grid, Box, Button, makeStyles } from '@material-ui/core';
import { store, ANSWER_ACTION, initialGameState } from '../../store';

const useStyles = makeStyles((theme) => ({
  selectionButton: {
    marginRight: theme.spacing(2),
  }
}));

export default function PageTemplate({
  data,
  pageContext
}) {
  const classes = useStyles();
  const { markdownRemark } = data
  const { frontmatter: { title, imageAlt, type, month, year, order: questionId, correctResponse, wrongResponse } } = markdownRemark
  const { next, totalNodes } = pageContext;
  const gameId = `${year}-${month}`;

  const { state, dispatch } = useContext(store);
  const { correct, answers } = state[gameId] || initialGameState;
  
  const answer = answers[questionId];

  const onClickGuessThis = () => {
    onClickGuess('this');
  }
  
  const onClickGuessThat = () => {
    onClickGuess('that');
  }
  
  const onClickGuess = (guess) => {
    dispatch({ type: ANSWER_ACTION, payload: { gameId, questionId, correct: guess === type }});
  }
  
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Box marginX={1}>
          <Typography variant="h1">{title}</Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={8}>
        <Img fluid={data.allImageSharp.nodes[0].fluid} alt={imageAlt}/>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Box marginX={1}>
          <Grid container spacing={3}>
            <>
              {answer === 'pending' && (
                <>
                  <Grid item xs={12}>
                    <Typography align="center">Is this a dog or a wizard?</Typography>
                  </Grid>
                  
                    <Grid item xs={6}>
                      <Button variant="outlined" fullWidth onClick={onClickGuessThis}>Dog</Button>
                    </Grid>
                    <Grid item xs={6}>
                      <Button variant="outlined" fullWidth onClick={onClickGuessThat}>Wizard</Button>
                    </Grid>
                </>
              )}
              {answer === 'correct' && (
                <Grid item xs={12}>
                  <Typography>{correctResponse}</Typography>
                </Grid>
              )}
              {answer === 'wrong' && (
                <Grid item xs={12}>
                  <Typography>{wrongResponse}</Typography>
                </Grid>
              )}
              {answer !== 'pending' && (
                <Grid item xs={12}>
                  {next && (
                    <Link to={next.slug}>Next: {next.title}</Link>
                  )}
                </Grid>
              )}
            </>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box marginLeft={1} marginRight={1}>
          <Typography>Score: {correct} / {totalNodes - 1}</Typography>
        </Box>
      </Grid>
    </Grid>
  )
}

export const pageQuery = graphql`
  query($slug: String!, $imagePath: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        slug
        title
        month
        year
        order
        type
        imageAlt
        correctResponse
        wrongResponse
      }
    }
    allImageSharp(filter: {fixed: {originalName: {eq: $imagePath}}}) {
      nodes {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`